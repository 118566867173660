.spinnerButton {
    padding: 2px;
    border: 2px solid rgba(0, 0, 0, 0);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border-left-color: #FFFFFF;
    border-right-color: #FFFFFF;
    border-top-color: #FFFFFF;
    animation: spin 1s ease infinite;
  }
  
  .spinnerButtonGreen {
    padding: 2px;
    border: 2px solid rgba(0, 0, 0, 0);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border-left-color: #01736C;
    border-right-color: #01736C;
    border-top-color: #01736C;
    animation: spin 1s ease infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  .cursorPointer{
    cursor: pointer;
  }