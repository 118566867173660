.DragDropBackground {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 366px;
    width: 100%;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='RGB(133, 123, 226)' stroke-width='2' stroke-dasharray='5' stroke-dashoffset='34' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
}

.DragDropBackgroundDisabled {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 366px;
    width: 100%;
    height: 120px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='RGB(242, 245, 249)' rx='20' ry='20' stroke='RGB(210, 214, 217)' stroke-width='2' stroke-dasharray='5' stroke-dashoffset='34' stroke-linecap='square'/%3e%3c/svg%3e");
    box-shadow: 0;
    border-radius: 8px;
    text-align: center;
    cursor: auto;
    height: 140px;
}

.seleccionaLabel {
    font-family: 'rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.0120588em;
    color: #203442;
    width: 83px;
    margin: 0 5px 0 9px;
    height: 16px;
}

.seleccionaLabelDisabled {
    font-family: 'Interstate';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.0120588em;
    color: #203442;
}

.arrastraLabel {
    font-family: 'rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.0120588em;
    color: #5143D5;
    text-decoration: underline;
}

.arrastraLabelDisabled {
    font-family: 'rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.0120588em;
    color: #79858E;
    width: 187px;
    margin: 0;
    height: 16px;
    text-decoration: underline;
}

.drop {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.0120588em;
    color: #79858E;
}

.ErrorDragDrop {
    width: 100%;
    height: 40px;
    font-family: 'Interstate';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0120588em;
    color: #E53935;
    margin: 9px 0 0 0;
    text-align: center;
}

.nameFileAdjunto {
    font-family: 'Rubik' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    letter-spacing: 0.0120588em !important;
    color: #392F95 !important;
}


.nameFileNoAdjunto {
    font-family: 'Interstate';
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 16px !important;
    letter-spacing: 0.0120588em;
    color: #E53935;
    float: left;
    margin-right: 20px !important;
}

.errorFileNoAdjunto {
    font-family: 'Interstate';
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 16px !important;
    letter-spacing: 0.0120588em;
    color: #E53935;
    float: left;
    margin: 13px 0 0 30px !important;
}

.nameFileNoAdjunto {
    font-family: 'Rubik'!important ;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    letter-spacing: 0.0120588em !important;
    color: #B71C1C !important;

}

.tagAdjuntado {
    align-items: center;
    padding: 0px 7px !important;
    width: 101px;
    height: 26px;
    top: 6px;
    background: #F1F8E9;
    border: 1px solid #7CB342;
    border-radius: 8px;
    float: left;
    font-size: 12px !important;
    font-family: Rubik;
    margin: 13px 9px 9px !important;
}

.tagAdjuntado svg {
    width: 24px;
    margin-top: 4px
}

.tagNoAdjuntado {
    font-family: 'Rubik' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    letter-spacing: 0.0120588em !important;
    color: #B71C1C !important;
}

.errorDrag {
    font-family: 'Interstate';
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 16px !important;
    letter-spacing: 0.0120588em;
    color: #203442;
    margin: 15px !important;
    float: left;
}

.groupDrag {
    border: 1px solid #5D5FEF;
    border-radius: 8px;
    padding-top: 9.5px;
    padding-bottom: 9.5px;
    padding-left: 16px;
    margin-bottom: 8px;
    margin-right: 7px;
}

.groupDragError {
    border: 1px solid #FFCDD2;
    border-radius: 8px;
    padding-top: 9.5px;
    padding-bottom: 9.5px;
    padding-left: 16px;
    margin-bottom: 4px;
    margin-right: 7px;
}


.sizeFile {
    font-family: 'Interstate';
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 16px !important;
    color: #A6AEB3;
}

/* Antecedentes adjuntos */
.formatoLabel {
    font-family: 'Interstate';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    display: contents;
    color: #67737C;
}

.boxAntecedentes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 24px;
    gap: 8px;
    width: 343px;
    height: 84px;
    left: 24px;
    top: 320px;
    background: #FFFFFF;
    border: 1px solid #D2D6D9;
    border-radius: 8px;
    margin-right: 11px !important;
    float: left;
}

.linkDoc {
    font-family: 'Interstate';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.0120588em;
    text-decoration-line: underline;
    color: #55798F;
    float: right;
}

.boxNoCargas {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 24px;
    gap: 8px;
    width: 99.5%;
    height: 84px;
    left: 24px;
    top: 704px;
    text-align: center;
    background: #FFFFFF;
    border: 1px solid #D2D6D9;
    border-radius: 8px;
}

.TitleForm {
    font-family: 'Interstate';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    /* identical to box height, or 20px */

    display: flex;
    align-items: center;
    letter-spacing: 0.0120588em;

    /* Tertiary 2 */

    color: #364855;
    margin: 30px 0 20px;
}
.groupCheck {
    border: 1px solid #7CB342;
    border-radius: 8px;
    padding-top: 9.5px;
    padding-bottom: 9.5px;
    padding-left: 16px;
    margin-bottom: 8px;
    margin-right: 7px;
}
.nameFileAdjuntoCheck {
    font-family: 'Rubik' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    letter-spacing: 0.0120588em !important;
    color: #33691E !important;
}
.spinner {
    padding: 2px;
    margin-left: 28px;
    border: 2px solid rgba(0, 0, 0, 0);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border-left-color: #392F95;
    border-right-color: #392F95;
    border-top-color: #392F95;
    animation: spin 1s ease infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  .DragDropBackground:hover{
    background-color: #5d5fef29;
    box-shadow: 0px 0px 2px 4px #5d5fef29;
    transition: background-color 0.5s ease-out 100ms,box-shadow 0.5s ease-out 100ms;
  }

  .DragDropBackgroundOnDrag {
    background-color: #5d5fef29;
    box-shadow: 0px 0px 2px 4px #5d5fef29;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 366px;
    width: 100%;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='RGB(133, 123, 226)' stroke-width='2' stroke-dasharray='5' stroke-dashoffset='34' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 20px;
    transition: background-color 0.5s ease-out 100ms,box-shadow 0.5s ease-out 100ms;
    text-align: center;
    cursor: pointer;
}
  