.icon-menu-list {
    height: 24px;
    width: 24px;
    padding: 0 20px;
}



.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock {
    font-family: 'Interstate';
    font-style: normal;
    font-weight: 700 !important;
    font-size: 16px;
    line-height: 24px;
}

.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding.MuiListItem-button {
    color:#63717B;
    fill:#63717B;
    height: 55px;
    padding-left: 24px;
    font-family: 'Interstate';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding.MuiListItem-button:hover {
    background: #EBF8F7;
    font-family: 'Interstate';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    transition: color fill .15s ease-in-out, color fill .15s ease-in-out;
    color: #00736B !important;
    fill: #00736B !important;


}

/* .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium:hover {
    background-color: #EBF8F7;
} */

.MuiTypography-root.drawerTitle {
    color: #63717B;
    font-family: "Interstate";
    font-size: 14px;
    font-style: normal;
    font-weight: 700 !important;
    letter-spacing: 0.0120588em;
    line-height: 28px; 
    padding-left: 24px;
}

.titleContenedor {
    display: flex;
    align-items: center;
    height: 40px;
    width:100%;
}
.listDrawer .Mui-selected {
    color: #01736C !important;
    background-color: #E6F6F5 !important;
    fill: #01736C !important;
}

.Mui-selected .MuiListItemText-primary {
    font-weight: 700 !important;
}
.MuiDrawer-docked .MuiIconButton-root{margin-right:8px!important;}

.linkMenu .MuiTypography-root{ font-family: 'Interstate' !important;}
.linkMenu{
    font-weight: 700;
}

.linkMenu:hover{ 
    color: #01736C !important;
}


.Mui-selected .linkMenu {
    font-weight: 700 !important;
    color: #01736C !important;
  }

  .subMenu .MuiTypography-root{ font-family: 'Interstate' !important;}
  .subMenu{
      font-weight: 700;
      color: #63717B;
      padding-left: 84px;
      padding-top: 16px;
      padding-bottom: 16px;
      margin: 0;
  }
  
  .subMenu:hover{ 
      color: #01736C !important;
      fill:  #01736C !important;
      background-color: #E6F6F5;
  }
  
  
  .Mui-selected .subMenu {
      font-weight: 700 !important;
      color: #01736C !important;
    }
 
