 

.marginContainer {
  margin-top: 72px;
}



.row {
  margin-bottom: 7px;
}

/* Display 2 */
.Bienvenida {
  display: block;
  font-style: normal;
  font-size: 40px;
  letter-spacing: 0.0120588em;
  color: #00A499;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  letter-spacing: 0.0120588em;
  color: #4DBFB8;
}

.alSistema {
  display: block;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 47px;
  letter-spacing: 0.0120588em;
  color: #4DBFB8;
}

.BienvenidaTexto {
  display: block;
  font-family: 'Interstate';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.0120588em;
  color: #203442;
}