 @import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap");
 @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
 @import url("https://use.typekit.net/qju1gbn.css");

 @font-face {
   font-family: 'Interstate';
   src: url('../src/assets/fonts/Interstate-Regular.otf');
   font-style: normal;
   font-weight: bolder;
 }

 * {
   outline: none;
 }

 a:visited {
   color: #364855;
 }

 body {
   background-color: #F2F5F9;
   margin: 0;
   font-family: 'Interstate', 'Rubik', 'RubikFont', sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }

 .MuiContainer-root.MuiContainer-maxWidthLg {
   padding-left: 32px;
 }

 @media (prefers-reduced-motion: no-preference) {
   .App-logo {
     animation: App-logo-spin infinite 20s linear;
   }
 }

 .App-header {
   background-color: #282c34;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: white;
 }

 code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
     monospace;
 }

 .MuiOutlinedInput-input.MuiInputBase-input.Mui-disabled.MuiInputBase-inputSizeSmall {
   border: none;
   background: #E9EBEC;
   border-radius: 8px;
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   letter-spacing: 0.0120588em;
   color: #67737C;
 }

 .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.Mui-disabled.MuiInputBase-fullWidth.MuiInputBase-formControl {
   border: none;
   background: #E9EBEC;
   border-radius: 8px;
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   letter-spacing: 0.0120588em;
   color: #67737C;
 }

 .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
   text-transform: none;
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 700;
   font-size: 16px;
   line-height: 20px;
   text-align: center;
   letter-spacing: 0.0120588em;
   color: #01736C;
   width: 18%;
 }

 .MuiButtonBase-root.MuiTab-root.MuiTab-textColorSecondary.Mui-selected {
   text-transform: none;
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 700;
   font-size: 16px;
   line-height: 20px;
   text-align: center;
   letter-spacing: 0.0120588em;
   color: #392F95;
   width: 16%;
 }

 .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
   width: 18%;
   text-transform: none;
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 700;
   font-size: 16px;
   line-height: 20px;
   text-align: center;
   letter-spacing: 0.0120588em;
   color: #79858E;
 }

 .MuiButtonBase-root.MuiTab-root.MuiTab-textColorSecondary {
   width: 16%;
   text-transform: none;
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 700;
   font-size: 16px;
   line-height: 20px;
   text-align: center;
   letter-spacing: 0.0120588em;
   color: #79858E;
 }

 .MuiTabs-indicator {
   height: 2px !important;
 }

 .MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.Mui-disabled.btn {
   background-color: #E9EBEC;
   box-shadow: none;
   border: none;
 }

 .MuiTypography-root.titleAccordion {
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 700;
   font-size: 18px;
   line-height: 28px;
   letter-spacing: 0.0120588em;
   color: #364855;
 }

 .MuiTypography-root.titleAccordionOpen {
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 700;
   font-size: 18px;
   line-height: 28px;
   letter-spacing: 0.0120588em;
   color: #01A49A;
 }

 .MuiTypography-root.subTitleAccordion {
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 24px;
   letter-spacing: 0.0120588em;
   color: #4D5D68;
 }

 .MuiButton-root.Mui-disabled.btnEliminarRegistro {
   text-transform: none;
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 700;
   font-size: 16px;
   line-height: 20px;
   text-align: center;
   letter-spacing: 0.0021em;
   color: #909AA1;
 }

 .MuiButton-root.btnEliminarRegistro {
   text-transform: none;
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 700;
   font-size: 16px;
   line-height: 20px;
   text-align: center;
   letter-spacing: 0.0021em;
   color: #00837A;
 }


 .loader {
   border: 5px solid #CCEDEB;
   margin-left: 50%;
   margin-top: 10%;
   border-radius: 100%;
   width: 16px;
   height: 16px;
   animation-name: stretch;
   animation-duration: 0.7s;
   animation-delay: 0s;
   animation-direction: alternate;
   animation-iteration-count: infinite;
   animation-fill-mode: none;
   animation-play-state: running;
 }

 @keyframes stretch {
   0% {
     transform: scale(0.5);
     color: #01A49A;
     border-radius: 100%;
   }

   50% {
     background-color: #01A49A;
   }

   100% {
     transform: scale(1.5);
     background-color: #01A49A;
   }
 }



 .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollBody.MuiDialog-paperWidthMd.MuiDialog-paperFullWidth {
   box-shadow: 0px 2px 10px #364855;
   border-radius: 8px;
 }

 .btnCard {

   display: flex;
   border: none;
   box-shadow: none;
   height: 72px;
   width: 201px;
   background: #FFFFFF;
   box-shadow: 0px 2px 5px rgba(201, 207, 227, 0.75);
   border-radius: 8px;
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 700;
   font-size: 16px;
   line-height: 20px;
   align-items: center;
   text-align: center;
   letter-spacing: 0.0120588em;
   color: #203442;
 }

 .btnCard:hover {
   outline: 1px solid #C6C6C6;
   box-shadow: 1px 2px 5px rgba(201, 207, 227, 0.75)
 }

 .btnCard:active {
   outline: 1px solid #5143D5;
 }

 .btnSpan {
   margin-right: 32px;
   width: 8px;
   height: 57px;
   border-radius: 8px;
   background-color: #C6C6C6;
 }

 .btnCard:active .btnSpan {
   background-color: #5143D5;
 }

 .MuiFormHelperText-root.Mui-error {
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 700;
   font-size: 14px;
   line-height: 16px;
   letter-spacing: 0.0120588em;
   color: #E53935;
 }


 .MuiTouchRipple-root {
   display: none;
 }

 .MuiFormGroup-root.styledLabel {
   font-family: 'Interstate' !important;
 }

 .MuiTableContainer-root::-webkit-scrollbar {
   height: 8px;
 }

 .MuiTableContainer-root::-webkit-scrollbar-track {
   background: #ffffff;
 }

 .MuiTableContainer-root::-webkit-scrollbar-thumb {
   background: #4DC0B8;
 }

 .MuiTableContainer-root::-webkit-scrollbar-thumb:hover {
   background: #399e97;
 }



 .MuiDialogContent-root::-webkit-scrollbar {
   height: 8px;
   width: 8px;
 }

 .MuiDialogContent-root::-webkit-scrollbar-track {
   background: #ffffff;
 }

 .MuiDialogContent-root::-webkit-scrollbar-thumb {
   background: #4DC0B8;
 }

 .MuiDialogContent-root::-webkit-scrollbar-thumb:hover {
   background: #399e97;
 }

 .tableCollapseLicense td {
   white-space: nowrap;
 }

 .shadowCelda {
   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(0 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' transform='translate(16)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
 }

 .closeIconAlert {
   cursor: pointer;
 }

 .displayNone {
   display: none !important;
 }

 .icon-home {
   background: transparent url('./assets/icons/home.svg') no-repeat center;
 }

 .icon-home-hover {
   background: transparent url('./assets/icons/home.svg') no-repeat center;
 }

 .icon-home:hover {
   width: 24px;
   height: 24px;
   background: transparent url('./assets/icons/home.svg') no-repeat center;
 }


 button span {
   display: none;
 }


 .titleContenedor {
   height: 40px;
   width: 100%;
 }

 .MuiDrawer-paper .MuiIconButton-root {
   margin: 0 10px;
 }





 .listDrawer .Mui-selected {
   fill: #67737c !important;
   background-color: #e6f6f5 !important;
   color: #67737c !important;
 }


 .MuiTypography-root.name {
   font-family: 'Interstate';
   font-style: normal;
   font-weight: bold;
   font-size: 14px;
   letter-spacing: 0.0120588em;
   display: flex;
   color: #FFFFFF;
 }

 .MuiTypography-root.job {
   font-family: 'Interstate';
   font-style: normal;
   font-weight: normal;
   font-size: 12px;
   letter-spacing: 00.0120588em;
   display: flex;
   align-items: center;
   color: #FFFFFF;
 }

 .MuiTypography-root.iniciarsesion {
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   align-self: center;
   color: #FFFFFF;
   cursor: pointer;
   margin: 0;
   padding: 10px 17px;
   transition: color 0.15s;
 }

 .MuiTypography-root.iniciarsesion:hover {
   color: #4DC0B8;
   cursor: pointer;
   transition: color .15s ease-in-out, color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
 }

 .MuiTypography-root.cerrarsesion {
   vertical-align: sub;
   font-family: 'Interstate';
   font-style: normal;
   font-weight: normal;
   font-size: 16px;
   align-self: center;
   color: #FFFFFF;
   fill: #FFFFFF;
   cursor: pointer;
   margin: 0;
   height: 45px;
   width: 155px;
   padding: 8px 0px 8px 17px;
   transition: color fill 0.15s;
 }

 .MuiTypography-root.cerrarsesion:hover {
   fill: #4DC0B8;
   color: #4DC0B8;
   cursor: pointer;
   transition: color fill .15s ease-in-out, color fill .15s ease-in-out;
   ;
 }

 .MuiTypography-root.cerrarsesion svg {
   margin: -4px 0 0 18px;
 }

 .logo {
   padding: 10px;
 }

 .MuiToolbar-root.toolbar {
   background-color: #00837D;
 }

 .MuiButtonBase-root.textoLista {
   font-family: 'Interstate';
   font-style: normal;
   font-weight: bold;
   font-size: 16px;
   color: #00837A;
 }

 .icon_avatar {
   width: 44px;
   height: 44px;
   align-self: center;
 }

 .icon_notify {
   width: 32px;
 }


 .boxFiles::-webkit-scrollbar {
   width: 4px;
   height: auto;
 }

 .boxFiles::-webkit-scrollbar-track {
   border-radius: 8px;

 }

 .boxFiles::-webkit-scrollbar-thumb {
   background: #79858E;
   border-radius: 10px;

 }

 .boxFiles::-webkit-scrollbar-thumb:hover {
   background: #68747c;
 }

 .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAlert-root.MuiAlert-standardSuccess.MuiAlert-standard {
   padding-bottom: 0px;
   padding-top: 0px;
 }


 .contenedor-loader {
   padding: auto;
   text-align: center;
 }

 .modal-loader {
   text-align: center;
 }

 .modal-loader>.custom-loader-visa {
   margin-left: 48.5%;
   border: 5px solid #CCEDEB;
   border-radius: 100%;
   width: 16px;
   height: 16px;
   margin-bottom: 26px;
   animation-name: stretch;
   animation-duration: 0.7s;
   animation-delay: 0s;
   animation-direction: alternate;
   animation-iteration-count: infinite;
   animation-fill-mode: none;
   animation-play-state: running;
 }

 .contenedor-loader>.custom-loader-visa {
   margin-left: 48.5%;
   border: 5px solid #CCEDEB;
   border-radius: 100%;
   margin-top: 6%;
   width: 16px;
   height: 16px;
   margin-bottom: 26px;
   animation-name: stretch;
   animation-duration: 0.7s;
   animation-delay: 0s;
   animation-direction: alternate;
   animation-iteration-count: infinite;
   animation-fill-mode: none;
   animation-play-state: running;
 }

 .MuiStepIcon-root.Mui-completed {
   color: #5D5FEF !important;
 }

 .MuiStepIcon-root.Mui-active {
   color: #5D5FEF !important;
 }

 .MuiStepIcon-text {
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 400;
   font-size: 14px !important;
   line-height: 14px;
   color: #FFFFFF;
 }

 .MuiStepLabel-root.Mui-disabled.MuiSvgIcon-root {
   color: #E9EBEC !important;
 }

 .menuForm .MuiPaper-root {
   box-shadow: 0px 2px 10px #C9CFE3;
   border-radius: 8px;
 }

 .menuForm .MuiPaper-root .MuiMenu-list {
   width: 140px !important;
 }


 .menuForm li {
   border-top: 1px solid #D2D6D9;
   color: #364855;
   cursor: auto;
 }


 .menuForm li:first-child {
   border-top: none;
 }

 .littleMenu.MuiPaper-root {
   box-shadow: 0px 2px 10px #C9CFE3;
   border-radius: 8px;
 }

 .littleMenu.MuiPaper-root.MuiMenu-list {
   width: 140px !important;
 }


 .littleMenu li {
   color: #01736C !important;
   cursor: auto;
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 12px;
   /* identical to box height, or 171% */
   letter-spacing: 0.0120588em;
   border-top: 1px solid #D2D6D9;
   color: #364855;
   cursor: pointer;
   padding-bottom: 12px !important;
   padding-top: 12px !important;
 }

 .littleMenu li:hover {
   background-color: #E6F6F5;
 }

 .littleMenu ul {
   padding: 0 !important;
 }

 .littleMenu li:first-child {
   border-top: none;
 }

 .MuiPopover-paper {
   border-radius: 8px !important;
 }

 .MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root {
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 20px;
   /* identical to box height, or 171% */

   letter-spacing: 0.0120588em;
   padding: 16px;
 }


 .menuForm li:first-child {
   border-top: none;
 }

 .MuiChip-root.MuiChip-outlined {
   font-family: 'Rubik';
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 16px;
   color: #364855;
   padding: 0;
   width: auto;
   height: 24px;
 }

 .MuiChip-root.MuiChip-outlined.MuiChip-colorPrimary {
   background: #CCEDEB;
   border: 1px solid #00A499;
   border-radius: 8px;

 }

 .MuiChip-root.MuiChip-outlined.MuiChip-colorSecondary {
   background: #FFF8E1;
   border: 1px solid #FFB300;
   border-radius: 8px;

 }

 .MuiChip-root.MuiChip-outlined.MuiChip-colorInfo {
   background: #EEECFB;
   border: 1px solid #5143D5;
   border-radius: 8px;

 }

 .MuiChip-root.MuiChip-outlined.MuiChip-colorSuccess {
   background: #F1F8E9;
   border: 1px solid #7CB342;
   border-radius: 8px;
 }

 .MuiAlert-message {
   font-family: 'Rubik' !important;
   font-style: normal !important;
   font-weight: 500 !important;
   font-size: 12px !important;
   line-height: 16px !important;
   letter-spacing: 0.0120588em !important;
   color: #203442 !important;
   padding: 0 !important;
   display: flex;
   align-items: center;
 }

 .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAlert-root.MuiAlert-standardInfo.MuiAlert-standard {
   padding: 0 !important;
   padding-left: 14px !important;
   padding-right: 14px !important;
   border-radius: 8px !important;
 }

 .MuiChip-root.MuiChip-outlined.MuiChip-deletable.MuiChip-deletableColorDefault {
   background-color: #EEECFB;
   border: 1px solid #978EE6;
   border-radius: 8px;
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 16px;
   letter-spacing: 0.0120588em;
   color: #203442;
   padding-right: 8px;
 }

 .filtroFont {
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 125%;
   letter-spacing: 0.0120588em;
   color: #4D5D68;
 }

 .TituloResultadoTabla {
   font-size: 28px;
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 700;
   font-size: 28px;
   line-height: 36px;
   color: #4C4C4C;
 }

 .subtituloResultadoTabla {
   font-size: 16px;
 }

 .titulo2 {
   font-size: 18px !important;
   font-weight: bold !important;
   color: #203442 !important
 }

 .tagEstadoCasos {
   flex-direction: row;
   align-items: center;
   padding: 4px 9px;
   box-sizing: border-box;
   float: left;
   border-radius: 8px;
   width: auto;
   height: 28px;
   left: 67px;
   top: 131px;
   text-align: center;
   font-size: 12px;
   white-space: nowrap;
 }

 .tagIngresado {
   background: #F1F8E9;
   border: 1px solid #7CB342;
 }

 .tagRevision {
   background: #FFF8E1;
   border: 1px solid #FFB300;
 }

 .tagAutorizado {
   background: #CCEDEB;
   border: 1px solid #00A499;
 }

 .tagRechazado {
   background: #FFEBEE;
   border: 1px solid #E53935;
 }

 .tagPAT {
   background: #EEECFB;
   border: 1px solid #5143D5;
 }

 .tagPAC {
   background: #CCEDEB;
   border: 1px solid #00A499;
 }


 .tagCaja {
   background: #F1F8E9;
   border: 1px solid #7CB342;
 }

 .textDetailModal {
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 20px;
   letter-spacing: 0.0120588em;
   color: #364855;
 }

 .textDetailModalBold {
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 700;
   font-size: 16px;
   line-height: 20px;
   letter-spacing: 0.0120588em;
   color: #364855;
 }

 /* input[type=file] {
   font-family: 'Rubik';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 19px;
   display: flex;
   align-items: center;
   letter-spacing: 0.0120588em;
   color: #A6AEB3;
 }


 input[type=file]::file-selector-button {
   margin-right: 16px;
   font-weight: bold;
   text-align: center;
   border-radius: 8px;
   letter-spacing: 0.0021em;
   text-transform: none;
   line-height: 20px;
   font-size: 16px;
   padding: 15px 16px;
   font-weight: 700;
   text-align: center;
   padding: 15px 22px;
   box-shadow: 0px 2px 2px rgba(1, 164, 154, 0.25);
   color: #00837A;
   background: #FFFFFF;
   border: solid 1px #00837A; 
 }

 input[type=file]::file-selector-button:disabled{
  border: 1px solid #79858E;
  border-radius: 8px;
  color: #79858E;
  background-color: #FFFFFF;  
}


 input[type=file]::file-selector-button:hover {
   color: #FFFFFF;
   background-color: #4DC0B8;
 }

 input[type=file]::file-selector-button:disabled {
  border: 1px solid #79858E;
  border-radius: 8px;
  color: #79858E;
  background-color: #FFFFFF;  
} */

 /* input[type=file]::file-selector-button:visited {
   border: 1px solid #01736C;
   border-radius: 8px;
   color: #01736C;
   background-color: #FFFFFF;
 } */

 .fileInfo {
   font-family: 'Rubik';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 19px;
   display: flex;
   align-items: center;
   letter-spacing: 0.0120588em;
   color: #203442;
   display: inline;
   padding-left: 4px;
 }

 .boxInfo {
   background: #F1F8E9;
   border: 1px solid #7CB342;
   border-radius: 8px;
   padding: 10px 14px;
   justify-content: start;
   align-items: flex-start;
   display: flex;
 }

 .titleInfoBox {
   font-family: 'Rubik';
   font-style: normal;
   font-weight: 500;
   font-size: 12px;
   line-height: 16px;
   letter-spacing: 0.0120588em;
   color: #203442;
 }

 .subTextInfoBox {
   font-family: 'Rubik';
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 16px;
   letter-spacing: 0.0120588em;
   color: #364855;
 }

 .textBoldBox {
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 700;
   font-size: 12px;
   line-height: 16px;
   letter-spacing: 0.0120588em;
   color: #203442;
 }

 .errorBox {
   background: #FFEBEE;
   border: 1px solid #E57373;
   border-radius: 8px;
   padding: 10px 14px;
   justify-content: start;
   align-items: flex-start;
   display: flex;
 }

 .fileNotSelect {
   font-family: 'Rubik';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 19px;
   align-items: center;
   letter-spacing: 0.0120588em;
   color: #A6AEB3;
   padding-left: 16px;
 }

 .fileSelect {
   font-family: 'Rubik';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 19px;
   align-items: center;
   letter-spacing: 0.0120588em;
   color: #364855;
   padding-left: 16px;
 }

 .firstTextModal {
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   text-align: center;
   letter-spacing: 0.0120588em;
   color: #4D5D68;
 }

 .blueModalText {
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   text-align: center;
   letter-spacing: 0.0120588em;
   color: #5D5FEF;
 }

 .boldModalText {
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 700;
   font-size: 16px;
   line-height: 24px;
   text-align: center;
   letter-spacing: 0.0120588em;
   color: #4D5D68;
 }

 .titulo2 {
   font-size: 18px !important;
   font-weight: bold !important;
   color: #203442 !important
 }

 .MuiTypography-root.MuiTypography-inherit.MuiLink-root.MuiLink-underlineHover {
   text-decoration: none !important;
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 14px;
   letter-spacing: 0.0120588em;
   text-decoration-line: underline;
   color: #364855 !important;
 }

 a {
   color: #364855;
 }

 .headerTable {
   width: 160px;
   height: 48px;
   background: transparent;
   font-size: 14px;
   color: #364855;
   padding: 8px 11px;
   min-width: 85px;
   font-weight: 400;
   font-family: "Interstate" !important;
 }


 .headerTableIzquierda,
 .tableBodyIzquierda {
   position: sticky;
   position: -webkit-sticky;
   left: -1px;
   z-index: 99;
   width: 113px;
   height: 48px;
   font-size: 14px;
   color: #364855;
   background-position: 0 0;
   background-repeat: repeat-y;
   background-size: 113px;
   background-color: white;
   min-width: 113px;
 }


 th.headerTableAsignar {
   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(0 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' transform='translate(16)' fill='%23f8f9f9'/%3E%3C/g%3E%3C/svg%3E");
 }

 th.headerTableIzquierda {
   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(124 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' fill='%23f8f9f9'/%3E%3C/g%3E%3C/svg%3E");
 }

 .headerAcciones {
   background-position: 0 0;
   background-repeat: repeat-y;
   background-size: 150px;
   background-color: #f8f9f9;
   min-width: 125px;
   position: sticky;
   right: -1px;
   z-index: 99;
   display: inline-flex;
   padding: 16px 30px !important;
 }

 .headerTableAcciones {
   background-position: 0 0;
   background-repeat: repeat-y;
   background-size: 150px;
   background-color: #f8f9f9;
   min-width: 150px;
   position: sticky;
   right: -1px;
   z-index: 99;
   display: inline-flex;
   padding: 16px 30px !important;
 }

 th.headerTableAcciones {
   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(0 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' transform='translate(16)' fill='%23f8f9f9'/%3E%3C/g%3E%3C/svg%3E");
 }

 td.headerTableAcciones {
   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(0 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' transform='translate(16)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
 }


 td.headerTableAcciones:hover {
   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(0 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' transform='translate(16)' fill='%23f6f6f6'/%3E%3C/g%3E%3C/svg%3E");
 }

 .BodyAcciones {
   background-position: 0 0;
   background-repeat: repeat-y;
   background-size: 150px;
   background-color: #FFFFFF;
   min-width: 150px;
   position: sticky;
   right: -1px;
   z-index: 99;
   display: inline-flex;
   padding: 16px 30px !important;
 }

 th.tableBodyAcciones {
   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(0 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' transform='translate(16)' fill='%23f8f9f9'/%3E%3C/g%3E%3C/svg%3E");
 }

 .tableBodyAsignar {
   position: sticky;
   right: -2px;
   background-position: 0 0;
   background-repeat: repeat-y;
   background-size: 164px;
   background-color: #FFFFFF;
   padding-left: 30px !important;
   height: 48px;
 }

 th.tableBodyAsignar {
   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(0 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' transform='translate(16)' fill='%23f8f9f9'/%3E%3C/g%3E%3C/svg%3E");
 }

 td.tableBodyAsignar {
   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='160' height='1' viewBox='0 0 160 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='160' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(0 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' transform='translate(16)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
 }

 .MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.recauAcc {
   border-radius: 8px;
   border: none;
   background: #FFFFFF;
   box-shadow: 0px 2px 5px rgba(201, 207, 227, 0.75);
 }

 .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.recauAcc::before {
   display: none;
 }

 .MuiAccordionDetails-root {
   padding: 0 !important;
 }

 .tagEstadoCasos {
   font-family: "Rubik";
   flex-direction: row;
   align-items: center;
   padding: 4px 9px;
   box-sizing: border-box;
   float: left;
   border-radius: 8px;
   width: auto;
   height: 28px;
   left: 67px;
   top: 131px;
   text-align: center;
   font-size: 12px;
   white-space: nowrap;
 }

 .tagRed {
   background: #FFEBEE;
   border: 1px solid #E53935;
 }

 .tagYellow {
   background: #FFF8E1;
   border: 1px solid #FFB300;
 }

 .tagPurple {
   background: #EEECFB;
   border: 1px solid #5143D5;
 }

 .tagGGreen {
   background: #CCEDEB;
   border: 1px solid #00A499;
 }

 .tagGreen {
   background: #F1F8E9;
   border: 1px solid #7CB342;
 }

 .MuiAccordionDetails-root.recauAcc {
   border-top: solid 2px rgba(201, 207, 227, 0.75);
   border-top-left-radius: 8px;
   border-top-right-radius: 8px;

 }

 .littleDetail {
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   letter-spacing: 0.0120588em;
   color: #4D5D68;
 }

 .littleDetailBlue {
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   letter-spacing: 0.0120588em;
   color: #5D5FEF;
 }

 .grayLabelForm {
   padding-bottom: 6px !important;
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 125%;
   letter-spacing: 0.0120588em;
   color: #364855;
 }

 .detailText2 {
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 125%;
   letter-spacing: 0.0120588em;
   color: #364855;
 }

 .grayTitleAlt {
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 125%;
   color: #364855;
 }

 .formGrayColor {
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   letter-spacing: 0.0120588em;
   color: #4D5D68;
 }

 .formGrayColorBold {
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 700;
   font-size: 16px;
   line-height: 24px;
   letter-spacing: 0.0120588em;
   color: #4D5D68;
 }

 .previredDot {
   border-radius: 50%;
   background-color: #978EE6;
   width: 10px;
   height: 10px;
   align-items: center;
   justify-content: center;
   align-self: center;
 }

 .labeTitleC {
   font-family: "Interstate";
   font-size: 10px;
   font-weight: 700;
 }

 .labelFileType {
   font-family: 'Interstate';
   font-size: 16px;
   font-weight: 700;
   line-height: 20px;
   letter-spacing: 0.0121em;
   color: #364855;
   padding-right: 24px;
 }

 .MuiAccordionSummary-root.MuiAccordionSummary-gutters.purpleAccordion {
   background-color: #EEECFB;
   box-shadow: none;
   border: none;
   border-radius: 8px;

 }

 .MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.purpleAccordion {
   box-shadow: none;
   border: none;
   border-radius: 8px;
 }

 .MuiTypography-root.textAccordion {
   color: #364855;
   font-family: 'Interstate';
   font-size: 16px;
   font-weight: 700;
   line-height: 20px;
   letter-spacing: 0.0121em;
 }

 .MuiAccordionDetails-root.purpleAccordion {
   background-color: #F8F9F9;
   border-bottom-left-radius: 8px;
   border-bottom-right-radius: 8px;
 }


 .MuiTypography-root.textFilesList {
   font-family: 'Rubik';
   font-size: 16px;
   font-weight: 500;
   line-height: 20px;
   letter-spacing: 0.0121em;
   color: #203442;
 }

 .detailTextC {
   font-family: "Interstate";
   font-size: 14px;
   font-weight: 400;
   line-height: 20px;
   letter-spacing: 0.0121em;
   color: #63717B;

 }

 .PACDot {
   border-radius: 50%;
   background-color: #4DBFB8;
   width: 10px;
   height: 10px;
   align-items: center;
   justify-content: center;
   align-self: center;
 }

 .PATDot {
   border-radius: 50%;
   background-color: #FFB300;
   width: 10px;
   height: 10px;
   align-items: center;
   justify-content: center;
   align-self: center;
 }

 .cajaDot {
   border-radius: 50%;
   background-color: #019DF4;
   width: 10px;
   height: 10px;
   align-items: center;
   justify-content: center;
   align-self: center;
 }

 .grayListText {
   color: #A6AEB3;
   font-family: 'Rubik';
   font-size: 16px;
   font-weight: 500;
   line-height: 20px;
   letter-spacing: 0.0121em;
 }

 .borderGray {
   text-align: center;
   width: 100%;
   border: 1px solid #D2D6D9;
   border-radius: 8px;
   padding-top: 8%;
   padding-bottom: 8%;
 }

 .contador {
   display: flex;
   justify-content: space-between;
   padding-top: 6px;
   padding-left: 16px;
   padding-right: 16px;
 }

 .textoContador {
   font-family: "Interstate";
   font-size: 14px;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: 0.0121em;
   color: #909AA1;
 }

 .grayScroll::-webkit-scrollbar {
   width: 8px;
   max-height: 10px !important;
 }

 .grayScroll::-webkit-scrollbar-track {
   border-radius: 8px;
   background-color: transparent;

 }

 .grayScroll::-webkit-scrollbar-thumb {
   background: #cccccc;
   border-radius: 10px;

 }

 .grayScroll::-webkit-scrollbar-thumb:hover {
   background: #68747c;
 }

 .MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
   box-shadow: 0px 1px 2px 0px #C9CFE3;
 }

 .MuiMenuItem-root.Mui-disabled.MuiMenuItem-gutters.MuiButtonBase-root.Mui-disabled.littleMenu {
   width: 100% !important;
   background-color: #E9EBEC !important;
   padding: 12px 16px !important;
   font-family: 'Interstate';
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 20px;
   letter-spacing: 0.0120588em;
   color: #585b5e !important;
   border-bottom: 1px solid #D2D6D9;
 }

 .tableBodyEspecial {
   position: sticky;
   right: 120px;
   min-width: 120px;
   background-position: 0 0;
   background-repeat: repeat-y;
   background-size: 150px;
   background-color: #FFFFFF;
   padding-left: 30px !important;
   height: 48px;
 }

 th.tableBodyEspecial {
   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(0 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' transform='translate(16)' fill='%23f8f9f9'/%3E%3C/g%3E%3C/svg%3E");
 }

 td.tableBodyEspecial {
   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(0 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' transform='translate(16)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
 }

 th.headerTableEspecial {
   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(0 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' transform='translate(16)' fill='%23f8f9f9'/%3E%3C/g%3E%3C/svg%3E");
 }

 .headerTableEspecial {
   position: sticky;
   right: 120px;
   min-width: 120px;
   height: 48px;
   background-position: 0 0;
   background-repeat: repeat-y;
   background-size: 150px;
   background-color: #fafafa;
   font-size: 14px;
   color: #364855;
   padding-left: 30px !important;
 }